<template>
  <v-container
    fill-height
    class="pa-0"
    style="background-color: white;"
  >
    <v-card
      flat
      height="100%"
      width="100%"
    >
      <v-toolbar
        flat
        class="sticky-toolbar"
      >
        <v-btn
          icon
          rounded
          @click.stop="$router.back()"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t('actions|add_new_client') }}</v-toolbar-title>
      </v-toolbar>

      <v-stepper
        v-model="e6"
        vertical
        flat
        class="fill-height"
      >
        <v-stepper-step
          :complete="e6 > 1"
          step="1"
        >
          {{ $t('actions|add_to') }}
          <small
            v-if="selectedSource"
            class="mt-1"
          >
            {{ selectedSource }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-select
            v-model="selectedSource"
            :items="availableSections"
            item-text="text"
            item-value="text"
            item-disabled="disabled"
            :label="$t('clients|client_is_provided_for')"
            dense
            outlined
            class="mt-2"
            @input="e6++"
          />
        </v-stepper-content>

        <v-stepper-step
          :complete="e6 > 2"
          step="2"
        >
          {{ $t('clients|select_client_profile_type') }}
          <small
            v-if="selected"
            class="mt-1"
          >
            {{ selected }}
          </small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-radio-group
            v-show="selected === null"
            v-model="selected"
            class="mb-5 pa-0"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-card
                  class="d-inline-flex align-center pa-2"
                  outlined
                  rounded
                  flat
                  width="100%"
                >
                  <div class="row align-center ml-2 pr-8">
                    <v-radio
                      :label="$t('actions|add_client_with_existing_lavvira_account')"
                      color="primary"
                      value="Add client with existing lavvira account"
                      class="mt-2 pr-8 py-2"
                    />
                    <v-icon
                      style="position: absolute; right: 4%;"
                      color="primary"
                      right
                    >
                      mdi-account
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px;"
                  >
                    {{ $t('expressions|request_to_client_with_lavvira_account') }}
                  </span>
                </div>
                <v-card
                  class="d-inline-flex align-center pa-2 mt-4"
                  outlined
                  rounded
                  flat
                  width="100%"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('actions|send_ivitation')"
                      color="primary"
                      value="Send email invitation"
                      class="mt-2"
                    />
                    <v-icon
                      style="position: absolute; right: 4%;"
                      color="primary"
                      right
                    >
                      mdi-account-plus
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|invitation_to_create_free_client_account') }}
                  </span>
                </div>
                <v-card
                  class="d-inline-flex align-center pa-2 mt-4"
                  outlined
                  rounded
                  flat
                  width="100%"
                >
                  <div class="row align-center ml-2">
                    <v-radio
                      :label="$t('actions|create_client_profile')"
                      color="primary"
                      value="Create a customer profile"
                      class="mt-2 pr-12"
                    />
                    <v-icon
                      style="position: absolute; right: 4%;"
                      color="primary"
                      right
                    >
                      mdi-account-plus-outline
                    </v-icon>
                  </div>
                </v-card>
                <div>
                  <span
                    class="font-weight-medium black--text"
                    style="font-size: 13px"
                  >
                    {{ $t('expressions|create_client_profile_yourself') }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-stepper-content>

        <v-stepper-step
          v-if="selected &&
            selected === 'Add client with existing lavvira account' ||
            selected === 'Send email invitation'
          "
          :complete="e6 > 3"
          step="3"
        >
          {{ $t('actions|enter_email_address') }}
        </v-stepper-step>

        <v-stepper-content
          v-show="selected && selected !== 'Create a customer profile'"
          step="3"
        >
          <div
            v-if="selected && selected === 'Send email invitation'"
          >
            <v-text-field
              v-model="userEmail"
              :label="$t('actions|enter_e_mail_here')"
              :error-messages="emailErrors"
              outlined
              dense
              :hint="$t('expressions|invitation_to_create_free_client_account')"
              persistent-hint
              class="mt-1"
              append-icon="mdi-close"
              @click:append="clearField()"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
            />
            <v-textarea
              v-model="additionalMessage"
              counter
              outlined
              :rules="rules"
              :label="$t('cases|message') + '...'"
              value=""
              class="mt-3"
            />
          </div>

          <div
            v-if="selected && selected === 'Add client with existing lavvira account'"
          >
            <v-text-field
              v-model="userEmail"
              :label="$t('actions|enter_e_mail_here')"
              :error-messages="emailErrors"
              outlined
              dense
              :hint="$t('actions|add_client_with_existing_lavvira_account')"
              persistent-hint
              class="mx-4 mt-2"
              append-icon="mdi-close"
              @click:append="clearField()"
              @input="$v.userEmail.$touch()"
              @blur="$v.userEmail.$touch()"
            />
            <v-textarea
              v-model="additionalMessage"
              counter
              :rules="rules"
              outlined
              :label="$t('cases|message') + '...'"
              value=""
              class="mx-4 mt-3"
            />
          </div>
          <div
            class="row pb-4 mt-2"
          >
            <v-btn
              text
              rounded
              small
              class="ml-3"
              @click="e6--; selected = null"
            >
              <v-icon
                class="mr-1"
              >
                mdi-undo
              </v-icon>
              {{ $t('actions|back') }}
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              class="mr-4"
              rounded
              dense
              small
              :loading="clientsLoading || accountLoading"
              :disabled="emailErrors.length > 0 || userEmail.length === 0 || additionalMessage.length > 125"
              min-width="100"
              @click="sendInvitation(userEmail, selected)"
            >
              {{ $t('actions|submit') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step
          v-if="selected === 'Create a customer profile'"
          :complete="e6 > 3"
          step="3"
        >
          {{ $t('clients|select_client_profile_type') }}
          <small
            v-if="clientType"
            class="mt-1"
          >
            <!-- Capitalize first letter -->
            {{ clientType.charAt(0).toUpperCase() + clientType.slice(1) }}
          </small>
        </v-stepper-step>

        <v-stepper-content
          v-show="selected === 'Create a customer profile'"
          step="3"
        >
          <v-select
            v-if="selected === 'Create a customer profile'"
            v-model="clientType"
            :items="clientTypes"
            :label="$t('actions|choose_client_type')"
            item-text="title"
            item-value="value"
            dense
            outlined
            class="mt-2"
            @input="e6++"
          />
          <v-btn
            text
            rounded
            small
            class="ml-2"
            @click="e6--; selected = null"
          >
            <v-icon
              class="mr-1"
            >
              mdi-undo
            </v-icon>
            {{ $t('actions|back') }}
          </v-btn>
          <v-spacer />
        </v-stepper-content>

        <v-stepper-step
          v-if="selected === 'Create a customer profile'"
          step="4"
        >
          {{ $t('actions|complete_customer_profile') }}
        </v-stepper-step>
        <v-stepper-content
          step="4"
          class="ml-n1"
        >
          <IndividualClientForm
            v-if="clientType && clientType === 'individual' && selected === 'Create a customer profile'"
            ref="clientForm"
            :has-file-uploader="false"
            :has-toolbar="false"
            :actual-tab="actualTab"
            :clients-loading="clientsLoading"
            :account-loading="accountLoading"
            @get-back="getBack"
            @submit="submit"
          />
          <CompanyClientForm
            v-if="clientType && clientType === 'company' && selected === 'Create a customer profile'"
            ref="clientForm"
            :has-file-uploader="false"
            :has-toolbar="false"
            :actual-tab="actualTab"
            @get-back="getBack"
            @submit="submit"
          />
        </v-stepper-content>
      </v-stepper>
    </v-card>
  </v-container>
</template>

<script>
import { userHasAccess } from '@/utils/utils'
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import IndividualClientForm from '@/components/client/IndividualClientForm.vue'
import CompanyClientForm from '@/components/client/CompanyClientForm.vue'
import ClientActions from '@/pages/Clients/ClientMixins/ClientActions'
import generalMixin from '@/utils/generalMixin.js'

export default {
  components: {
    IndividualClientForm,
    CompanyClientForm
  },
  validations: {
    userEmail: { required, email }
  },
  mixins: [ClientActions, validationMixin, generalMixin],
  data () {
    return {
      dialog: false,
      e6: 1,
      selected: null,
      clientType: null,
      actionType: 'create',
      loading: false,
      error: null,
      rules: [v => v.length <= 125 || 'Max 125 characters'],
      hoverCloseButtonIcon: false,
      userEmail: '',
      additionalMessage: '',
      actualTab: null,
      selectedSource: ''
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      company: state => state.company.company,
      clients: state => state.clients.clients,
      companyClients: state => state.companyClients.companyClients,
      groupClients: state => state.groupClients.groupClients
    }),
    clientTypes () {
      return [
        {
          title: this.$t('clients|individual'),
          value: 'individual'
        },
        {
          title: this.$t('clients|company'),
          value: 'company'
        }
      ]
    },
    emailErrors () {
      const errors = []
      if (this.userEmail !== null && this.userEmail.length > 0) {
        if (!this.$v.userEmail.$dirty) return errors
        !this.$v.userEmail.email && errors.push(this.$t('warning|valid_email'))
        !this.$v.userEmail.required && errors.push(this.$t('warning|email_required'))
      }
      return errors
    },
    availableSections () {
      let myAvailableSections = []

      const getAccess = (_acc, _comp, _group, _tab, _from) => {
        return userHasAccess(_acc, _comp, _group, _tab, _from)
      }
      const accountAccess = getAccess(this.account, null, null, null, 'onlyAccountAccess')
      const companyAccess = getAccess(this.account, this.company, null, null, 'availableSections')

      if (this.account && !this.account.companyId) {
        myAvailableSections.push({
          text: this.$t('common|my') + ' ' + this.$t('clients|clients'),
          disabled: !accountAccess
        })
      }
      if (this.account && this.account.companyId && this.company) {
        const userRole = this.verifyUserRole(this.company, this.account)
        // verifyUserRole could be found in generalMixin
        if (userRole === 'regular') {
          myAvailableSections.push({
            text: this.company.companyData.companyName,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName,
                disabled: !companyAccess
              })
            }
          }
        } else {
          myAvailableSections.push({
            text: this.$t('common|my') + ' ' + this.$t('clients|clients'),
            disabled: !accountAccess
          })
          myAvailableSections.push({
            text: this.company.companyData.companyName,
            disabled: !companyAccess
          })
          for (let i = 0; i < this.company.groups.length; i++) {
            const group = this.company.groups[i]
            const participation = group.groupMembers.find(m => m._id === this.account._id)
            if (participation) {
              myAvailableSections.push({
                text: group.groupName,
                disabled: !companyAccess
              })
            }
          }
        }
      }
      return myAvailableSections
    },
    allInvitations () {
      let invitations = []
      if (this.account.invitationsSent && this.account.invitationsSent.length) invitations.push(...this.account.invitationsSent)
      if (this.company) {
        if (this.company.invitationsSent && this.company.invitationsSent.length) invitations.push(...this.company.invitationsSent)
        for (let i = 0; i < this.company.groups.length; i++) {
          const group = this.company.groups[i]
          if (group.invitationsSent && group.invitationsSent.length) invitations.push(...group.invitationsSent)
        }
      }
      return invitations
    }
  },
  watch: {
    dialog (value) {
      if (!value) {
        this.reset()
      }
    },
    selectedSource (value) {
      if (value) this.generateActualTab(this.selectedSource)
    },
    selected (value) {
      if (value) this.e6++
    }
  },
  mounted () {
    if (Object.keys(this.$route.params).length) {
      for (const key in this.$route.params) {
        if (Object.hasOwnProperty.call(this.$route.params, key)) {
          const obj = this.$route.params[key]
          if (key && key === 'actualTab') {
            this.actualClient = obj
            this.onAddClientEvent(obj)
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      addToast: 'toasts/addToast',
      inviteAccountToBecomeClient: 'clients/inviteAccountToBecomeClient',
      sendInvitationViaEmail: 'clients/sendInvitationViaEmail'
    }),
    onAddClientEvent (actualTab) {
      if (actualTab && actualTab.isCompanyTab) {
        this.selectedSource = actualTab.company.companyData.companyName
        this.e6 = 2
      }
      if (actualTab && actualTab.isGroupTab) {
        this.selectedSource = actualTab.group.groupName
        this.e6 = 2
      }
      if (actualTab && actualTab.isMyTab) {
        this.selectedSource = this.$t('common|my') + ' ' + this.$t('clients|clients')
        this.e6 = 2
      }
      this.actualTab = actualTab
      this.dialog = true
    },
    reset () {
      if (this.$refs.clientForm) this.$refs.clientForm.reset()
      this.clientType = null
      this.selected = null
      this.userEmail = ''
      this.additionalMessage = ''
      this.actualTab = null
      this.selectedSource = ''
      this.actionType = 'create'
      this.error = null
      this.hoverCloseButtonIcon = false
      this.loading = false
      this.e6 = 1
    },
    getBack () {
      this.$refs.clientForm.reset()
      this.selected = null
      this.e6 = 2
    },
    clearField () {
      this.userEmail = ''
    },
    async sendInvitation (email, selected) {
      let alreadyConnected
      let alreadyInvited
      const _id = this.account._id
      if (this.selectedSource) {
        await this.generateActualTab(this.selectedSource)
      }
      if (selected === 'Send email invitation') {
        const payload = {
          invitationEmail: email,
          invitationType: selected,
          additionalMessage: this.additionalMessage,
          senderType: this.actualTab.isMyTab ? this.account.accountType : this.actualTab.isCompanyTab ? 'company' : 'group',
          sharedWith: this.actualTab.isMyTab ? this.account._id : this.actualTab.isCompanyTab ? this.actualTab.company._id : this.actualTab.group._id,
          sharedWithType: this.actualTab.isMyTab ? 'accounts' : this.actualTab.isCompanyTab ? 'company' : 'groups'
        }
        await this.sendInvitationViaEmail({ _id, payload })
        this.dialog = false
        this.reset()
      } else {
        if (this.actualTab && this.actualTab.isMyTab) {
          alreadyConnected = this.clients.find(cl => cl.clientData.email === email)
          alreadyInvited = this.account.invitationsSent.filter(inv => {
            return inv.recipientEmail === email && inv.status === 'pending'
          })
        } else if (this.actualTab && this.actualTab.isCompanyTab) {
          this.companyClients.find(ccl => ccl.clientData.email === email)
          alreadyInvited = this.company.invitationsSent.filter(inv => {
            return inv.recipientEmail === email && inv.status === 'pending'
          })
        } else if (this.actualTab && this.actualTab.isGroupTab) {
          this.groupClients.find(gcl => gcl.clientData.email === email)
          alreadyInvited = this.actualTab.group.invitationsSent.filter(inv => {
            return inv.recipientEmail === email && inv.status === 'pending'
          })
        }
        if (alreadyConnected && alreadyConnected.realClient) {
          this.addToast({
            title: this.$t('warning|already_connected_with_account'),
            color: 'white',
            snackbarColor: 'warning'
          })
        } else if (alreadyInvited && alreadyInvited.length) {
          this.addToast({
            title: this.$t('expressions|already_sent_invitation_to_user'),
            color: 'white',
            snackbarColor: 'warning'
          })
        } else {
          const payload = {
            invitationEmail: email,
            invitationType: selected,
            additionalMessage: this.additionalMessage,
            senderType: this.actualTab.isMyTab ? this.account.accountType : this.actualTab.isCompanyTab ? 'company' : 'group',
            sharedWith: this.actualTab.isMyTab ? this.account._id : this.actualTab.isCompanyTab ? this.actualTab.company._id : this.actualTab.group._id,
            sharedWithType: this.actualTab.isMyTab ? 'accounts' : this.actualTab.isCompanyTab ? 'company' : 'groups'
          }
          try {
            await this.inviteAccountToBecomeClient({ _id, payload })
          } catch (error) {
            console.error(error, 'ERROR while inviting account')
          } finally {
            this.dialog = false
            this.reset()
          }
        }
      }
    },
    async generateActualTab (selectedSource) {
      if (selectedSource === this.$t('common|my') + ' ' + this.$t('clients|clients')) {
        this.actualTab = {
          isMyTab: true
        }
      } else if (this.company && selectedSource === this.company.companyData.companyName) {
        this.actualTab = {
          isCompanyTab: true,
          company: {
            _id: this.company._id,
            companyName: this.company.companyData.companyName
          }
        }
      } else {
        let selectedGroup
        this.company.groups.forEach(group => {
          if (selectedSource.includes(group.groupName)) {
            selectedGroup = group
          }
        })
        this.actualTab = {
          isGroupTab: true,
          group: selectedGroup
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.general-form {
  position: relative;
  display: flex;
  flex-direction: column;
}
.sticky-toolbar {
  position: sticky;
  top: 48px;
  z-index: 1;
}
</style>
